import { OmniElement, OmniStyle, OmniIcon, html } from 'omni-ui';
import { Om2Table, Om2IconMenu, om2FormModal } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import { openPptxHelper } from './helpers/pptx-helper.js';
import { OmniAppContainerMixin } from 'omni-app-container';

Om2IconMenu.register();
OmniStyle.register();
OmniIcon.register();
Om2Table.register();

/**
 * Class for Workflow Settings Element
 * @extends OmniElement
 */
export default class WorkflowSettings extends OmniAppContainerMixin(
  OmniElement
) {
  /**
   * Workflow Settings Lit Properties
   * @property {Array} settingsData - Array of objects that contain pptx and theme settings for individual clients
   * @property {Array} assignedClients - Array of client UUIDs that were assigned to the given framework template
   * @property {String} workflowId - UUID of the framework template
   * @property {Array} clientOptions - Array of objects containing client IDs and names
   */
  static get properties() {
    return {
      settingsData: { type: Array },
      assignedClients: { type: Array },
      workflowId: { type: String },
      clientOptions: { type: Array },
    };
  }

  constructor() {
    super();
    this.addEventListener('doAdd', () => this.addSettings());
  }

  updated(changedProperties) {
    if (changedProperties.has('assignedClients') && this.assignedClients) {
      // Wait until assignedClients property is set before initializing
      this._initClients();
    }
  }

  async _initClients() {
    const allClients = await api.listClients();
    const clientLookup = Object.fromEntries(
      allClients.map(c => [c.guid_client, { id: c.guid_client, name: c.name }])
    );

    this.clientOptions = this.assignedClients
      .map(({ uuid_client: id }) => clientLookup?.[id])
      .filter(client => !!client);
    this.clientOptions.unshift({ id: '*', name: '* (Default)' });
  }

  /**
   * @description Triggers page reload
   * @fires dispatchNewEvent#reload
   */
  reload() {
    this.dispatchNewEvent('reload');
  }

  /**
   * @description Triggers event to navigate to custom themes page
   * @param {string} clientData - Client UUID and name
   * Uses append crumb event to pass necessary data to theme page via event detail
   */
  _manageTheme = clientData => {
    this.navigateTo(`workflow/${this.workflowId}/theme/${clientData.id}`);
  };

  /**
   * @description Uploads PPTX files to a client setting
   * @param {string} clientData - Client UUID and name
   * Uses {@link om2FormModal} to construct a form that when submit, calls {@link updateWorkflowSettings}
   */
  async _uploadPPTTemplate(e, clientData) {
    const modal = om2FormModal({
      title: 'Upload PPTX Template',
      submitButtonText: 'Upload',
      additionalButtons: html`
        <button class="button is-text" @click=${() => modal.close()}>
          Cancel
        </button>
        <button
          class="button is-text"
          @click=${() => {
            modal.close();
            openPptxHelper(this.workflowId);
          }}
        >
          Template Helper
        </button>
      `,
      clientData,
      onSubmit: async form => {
        const copy = { ...form };

        await api.updateFrameworkTemplateSettings(
          this.workflowId,
          clientData.id,
          copy
        );
      },
      onSuccess: () => this.reload(),
      schema: {
        pptx_file: {
          title: 'Export Template',
          type: 'file',
          accept:
            '.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation',
          required: true,
        },
      },
    });
  }

  /**
   * @description Deletes theme settings and PPTX files from client settings
   * @param {string} clientData - Client UUID and name
   * @param {string} action - theme or pptx; flag to determine which setting to delete
   * Calls {@link deleteWorkflowSettings}
   */
  _deleteSettings = async (clientData, action) => {
    let settings;
    if (action === 'pptx') {
      settings = ['pptx_name', 'pptx_file'];
    }
    if (action === 'theme') {
      settings = ['theme_object'];
    }
    await api.deleteFrameworkTemplateSettings(
      this.workflowId,
      clientData.id,
      settings
    );
    this.reload();
  };

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    return html`
      <omni-style>
        <om2-table
          autosort
          autotooltip
          shadowed
          .search=${['name']}
          .columns=${[
            {
              label: 'client',
              key: 'name',
              isMain: true,
              isSortable: true,
            },
            {
              label: 'color theme',
              passthrough: true,
              template: client => {
                // uses client to find a match in settingsData and retrives themeObject if it exists
                const { theme_object: themeObject = {} } =
                  this.settingsData?.find(
                    setting => setting.uuid_client === client.id
                  ) || {};

                if (Object.keys(themeObject ?? {}).length === 0) {
                  return html` <td class="has-text-left">None</td> `;
                }
                return html`
                  <td class="has-text-left is-clamped">
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:informative:check"
                    ></omni-icon>
                  </td>
                `;
              },
            },
            {
              label: 'powerpoint template',
              passthrough: true,
              template: client => {
                // uses client to find a match in settingsData and retrieves pptxName if it exists
                const { pptx_name: pptxName = '' } =
                  this.settingsData?.find(
                    setting => setting.uuid_client === client.id
                  ) || {};

                if (!pptxName) {
                  return html` <td class="has-text-left">None</td> `;
                }
                return html`
                  <td class="has-text-left">
                    <button
                      style="vertical-align: middle"
                      @click=${() =>
                        api.downloadFrameworkTemplatePowerpoint(
                          this.workflowId,
                          client.id
                        )}
                      class="button is-text mr-4"
                      title="Download"
                    >
                      <omni-icon
                        class="is-size-2"
                        icon-id="omni:interactive:download"
                      ></omni-icon>
                    </button>
                    ${pptxName}
                  </td>
                `;
              },
            },
            {
              label: 'actions',
              passthrough: true,
              template: client => {
                /**
                 * Establish empty array to store delete function objects for use in om2-icon-menu
                 * @example
                 * {
                 *  label: Determines option name,
                 *  callback: Callback function to be executed on option select,
                 *  action: Flag to determine which setting should be deleted,
                 * }
                 */
                const deleteSettingsFunctions = [];
                const {
                  pptx_name: pptxName = '',
                  theme_object: themeObject = {},
                } =
                  this.settingsData?.find(
                    setting => setting.uuid_client === client.id
                  ) || {};
                if (Object.keys(themeObject ?? {}).length > 0) {
                  deleteSettingsFunctions.push({
                    label: 'Color Theme',
                    callback: this._deleteSettings,
                    action: 'theme',
                  });
                }
                if (pptxName?.trim()) {
                  deleteSettingsFunctions.push({
                    label: 'Powerpoint Template',
                    callback: this._deleteSettings,
                    action: 'pptx',
                  });
                }
                return html`
                  <td>
                    <omni-tooltip class="ml-4">
                      <button
                        slot="invoker"
                        @click=${() => this._manageTheme(client)}
                        class="button is-text"
                      >
                        <omni-icon
                          class="is-size-2"
                          icon-id="omni:informative:theme"
                        ></omni-icon>
                      </button>
                      <div slot="content">Customize Theme</div>
                    </omni-tooltip>
                    <omni-tooltip class="ml-4">
                      <button
                        slot="invoker"
                        @click=${e => this._uploadPPTTemplate(e, client)}
                        class="button is-text "
                      >
                        <omni-icon
                          class="is-size-2"
                          icon-id="omni:interactive:upload"
                        ></omni-icon>
                      </button>
                      <div slot="content">Upload PPTX</div>
                    </omni-tooltip>
                    <om2-icon-menu
                      class="ml-4"
                      .options="${deleteSettingsFunctions}"
                      iconId="omni:interactive:remove"
                      iconClass="is-size-2"
                      tooltip="Delete Theme/PPTX"
                      @change="${e =>
                        e.detail.value.callback(client, e.detail.value.action)}"
                      ?disabled=${deleteSettingsFunctions.length === 0}
                    >
                    </om2-icon-menu>
                  </td>
                `;
              },
            },
          ]}
          .data="${this.clientOptions ?? []}"
        >
          <slot name="header-start" slot="header-start"></slot>
          ${!this.clientOptions
            ? html`<div class="has-text-centered p-5" slot="body">
                <i>Loading...</i>
              </div>`
            : ''}
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('workflow-settings', WorkflowSettings);
