import { OmniElement, OmniStyle, html } from 'omni-ui';
import 'omni-campaign/src/components/campaign-step-grid.js';

OmniStyle.register();

export default class PreviewCampaignObject extends OmniElement {
  static get properties() {
    return {
      object: { type: Object },
      objectType: { type: String },
    };
  }

  render() {
    switch (this.objectType) {
      case 'framework-template':
        return html`
          <omni-style>
            <div class="box is-flex mb-6" style="overflow:auto">
              <campaign-step-grid
                .data=${{ framework_template: this.object }}
              ></campaign-step-grid>
            </div>
          </omni-style>
        `;
      default:
        return `Unknown object type: ${this.objectType}`;
    }
  }
}

customElements.define('preview-campaign-object', PreviewCampaignObject);
