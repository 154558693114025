import { OmniElement, OmniStyle, OmniIcon, html, css } from 'omni-ui';

OmniStyle.register();
OmniIcon.register();

/**
 * Class for Left Nav Element
 * Replicates left-nav design (not functionality) in portal for purposes of previewing styles
 * @extends OmniElement
 */
export default class LeftNav extends OmniElement {
  /**
   * Left Nav styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        .left-nav-wrapper {
          width: 21.5rem;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
        .nav-header {
          min-height: 5.143rem;
          padding: 0.286rem;
          background: var(--gradient-primary);
        }
        button.header-button {
          display: flex;
          align-items: center;
          border-radius: 50%;
          height: 2.286rem;
          width: 2.286rem;
          margin-right: 0.286rem;
          background-color: rgba(var(--rgb-core-light), 0.2);
          border: transparent;
          padding: 0px !important;
        }
        button.header-button:hover {
          color: transparent;
          background-color: rgb(var(--rgb-core-light));
          border: none;
        }
        button.header-button omni-icon {
          fill: rgb(var(--rgb-core-light));
        }
        button.header-button:hover omni-icon {
          fill: rgb(var(--rgb-primary));
        }
        .title-bar {
          display: flex;
          align-items: center;
          margin-top: 0.286rem;
          padding: 14px;
        }
        .title-bar span {
          color: rgb(var(--rgb-core-light));
          margin-left: 10px;
          font-size: 18px;
        }
        .nav-body {
          height: calc(21.5rem - 5.143rem);
          background: var(
            --omni-app-layout-drawer-bg,
            var(--omni-app-layout-drawer-bg-color, var(--gradient-black))
          );
          flex-grow: 1;
        }
        .body-bar {
          display: flex;
          flex-direction: column;
          color: rgb(var(--rgb-core-light));
          padding-top: 4rem;
          font-weight: 600;
          gap: 0.214rem;
        }
        .body-bar-row {
          padding: 0 0 0 2.571rem;
          margin: 0 1.49rem 0 0;
          min-height: 3rem;
          position: relative;
          border-radius: 0 0.571rem 0.571rem 0;
          display: flex;
          align-items: center;
          gap: 18px;
        }
        .overview {
          padding: 0 0 0 6.714rem;
          background: var(--gradient-primary);
        }
        .omni-style-flex {
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    return html`<omni-style class="omni-style-flex">
      <div class="left-nav-wrapper">
        <div class="nav-header">
          <div class="title-bar">
            <button class="header-button">
              <omni-icon
                class="is-size-1"
                icon-id="omni:interactive:back"
                aria-label="icon"
                role="img"
              ></omni-icon>
            </button>
            <span>Client Name</span>
          </div>
        </div>
        <div class="nav-body">
          <div class="body-bar">
            <div>
              <div class="body-bar-row">
                <omni-icon
                  style="fill: rgb(var(--rgb-core-light));"
                  class="is-size-1"
                  icon-id="omni:object:campaignActive"
                  aria-label="icon"
                  role="img"
                ></omni-icon>
                <span>Campaigns</span>
              </div>
            </div>
            <div class="body-bar-row overview">Overview</div>
          </div>
        </div>
      </div>
    </omni-style>`;
  }
}

customElements.define('left-nav', LeftNav);
