import { OmniElement, OmniStyle, OmniIcon, html } from 'omni-ui';
import { om2confirm, om2FormModal, Om2Table } from 'omni-campaign-ui';
import { api } from './helpers/api.js';

OmniStyle.register();
OmniIcon.register();
Om2Table.register();

export default class StepAssets extends OmniElement {
  constructor() {
    super();
    this.addEventListener('doAdd', () => this.addAsset());
  }

  static get properties() {
    return {
      data: { type: Array },
      uuid: { type: String },
    };
  }

  reload() {
    this.dispatchNewEvent('reload');
  }

  async addAsset() {
    om2FormModal({
      title: 'Add Guide',
      onSubmit: async data => api.createAsset(this.uuid, data),
      onSuccess: () => this.reload(),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        asset: {
          title: 'Guide URL',
          type: 'string',
          pattern: '.*\\S.*', // Contain at least one non-whitespace character
          htmlTitle: 'At least one non-whitespace character',
        },
      },
    });
  }

  async editAsset(asset) {
    om2FormModal({
      title: 'Edit Guide',
      data: asset,
      onlySubmitChanges: true,
      onSubmit: obj => api.updateAsset(this.uuid, asset.uuid_step_asst, obj),
      onSuccess: () => this.reload(),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        asset: {
          title: 'Guide URL',
          type: 'string',
        },
      },
    });
  }

  async deleteAsset(asset) {
    const callback = async () => {
      await api.deleteAsset(this.uuid, asset.uuid_step_asst);
      this.reload();
    };
    om2confirm(`Permanently delete guide "${asset.name}"?`, {
      callback,
      type: 'warning',
    });
  }

  render() {
    return html`
      <omni-style>
        <om2-table
          autosort
          autotooltip
          shadowed
          .search=${['name']}
          .columns=${[
            {
              label: 'name',
              key: 'name',
              isSortable: true,
              isMain: true,
            },
            {
              label: 'description',
              key: 'description',
              isSortable: true,
            },
            {
              label: 'content type',
              key: 'content_type',
              template: ct =>
                html`<td class="is-clamped">
                  <span>${ct.name}</span>
                </td>`,
              // Omni table can't sort column with nested key
            },
            {
              label: 'asset',
              key: 'asset',
              isSortable: true,
            },
            {
              label: 'actions',
              passthrough: true,
              template: asset =>
                html` <td>
                  <button
                    @click=${() => this.editAsset(asset)}
                    class="button is-text"
                    title="Edit"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:edit"
                    ></omni-icon>
                  </button>
                  <button
                    @click=${() => this.deleteAsset(asset)}
                    class="button is-text ml-4"
                    title="Delete"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:trash"
                    ></omni-icon>
                  </button>
                </td>`,
            },
          ]}
          .data="${this.data}"
        >
          <slot name="header-start" slot="header-start"></slot>
          <slot name="header-end" slot="header-end"></slot>
          ${!this.data || this.data.length === 0
            ? html`
                <div class="has-text-centered p-5" slot="body">
                  <i>
                    ${this.data
                      ? 'There are currently no guides.'
                      : 'Loading...'}
                  </i>
                </div>
              `
            : ''}
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('step-assets', StepAssets);
