import { OmniElement, OmniStyle, OmniIcon, html } from 'omni-ui';
import { om2alert, om2confirm, om2FormModal, Om2Table } from 'omni-campaign-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { showLoader } from './helpers/util.js';
import { api } from './helpers/api.js';

OmniStyle.register();
OmniIcon.register();
Om2Table.register();

export default class StepModules extends OmniAppContainerMixin(OmniElement) {
  constructor() {
    super();
    this.addEventListener('doAdd', () => this.addModule());
  }

  static get properties() {
    return {
      data: { type: Array },
      uuid: { type: String },
      stepId: this.routeParamProperty({ name: 'stepId' }),
      stageId: this.routeParamProperty({ name: 'stageId' }),
      workflowId: this.routeParamProperty({ name: 'workflowId' }),
    };
  }

  reload() {
    this.dispatchNewEvent('reload');
  }

  async addModule() {
    const moduleChoices = (
      await showLoader(
        this,
        api.listModules({ fields: ['name', 'uuid_module'] })
      )
    )
      .filter(m =>
        this.data.every(m2 => m2.module.uuid_module !== m.uuid_module)
      )
      .map(m => ({ name: m.name, id: m.uuid_module }));

    if (moduleChoices.length === 0) {
      om2alert('All available modules have already been added');
      return;
    }

    om2FormModal({
      title: 'Add Module',
      onSubmit: async data => {
        await api.addStepModules(this.uuid, [data]);
      },
      onSuccess: () => this.reload(),
      schema: {
        uuid: {
          title: 'Module',
          type: 'string',
          enum: moduleChoices,
          hasSearch: true,
        },
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
          default: this.data.length + 1,
        },
      },
    });
  }

  async editModule(module) {
    const data = {
      display_order: module.display_order,
    };

    om2FormModal({
      title: 'Edit Module',
      data,
      onSubmit: async submitData => {
        // eslint-disable-next-line no-param-reassign
        submitData.uuid = module.module.uuid_module;
        await api.updateStepModules(this.uuid, [submitData]);
      },
      onSuccess: () => this.reload(),
      schema: {
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
        },
      },
    });
  }

  async openModule(module) {
    const url = `workflow/${this.workflowId}/${this.stageId}/${this.stepId}/module/${module.module.uuid_module}`;
    this.navigateTo(url);
  }

  removeModule(module) {
    const callback = async () => {
      await api.removeStepModule(this.uuid, module.module.uuid_module);
      this.reload();
    };

    om2confirm(`Permanently delete module "${module.module.name}"?`, {
      callback,
      type: 'warning',
    });
  }

  render() {
    return html`
      <omni-style>
        <om2-table
          autosort="display_order,asc"
          autotooltip
          shadowed
          .search=${[['module', 'name']]}
          .columns=${[
            {
              label: 'name',
              key: 'module',
              isMain: true,
              template: module =>
                html`<td class="is-main is-clamped">
                  <span>${module.name}</span>
                </td>`,
              // Omni table can't sort column with nested key (See OU-104)
            },
            {
              label: 'order',
              key: 'display_order',
              isSortable: true,
            },
            {
              label: 'id',
              key: 'module',
              template: module =>
                html`<td class="is-clamped">
                  <span>${module.uuid_module}</span>
                </td>`,
              // Omni table can't sort column with nested key (See OU-104)
            },
            {
              label: 'description',
              key: 'module',
              template: module =>
                html`<td class="is-clamped">
                  <span>${module.description}</span>
                </td>`,
              // Omni table can't sort column with nested key (See OU-104)
            },
            {
              label: 'type',
              key: 'module',
              template: module =>
                html`<td class="is-clamped">
                  <span>${module.module_type}</span>
                </td>`,
              // Omni table can't sort column with nested key
            },
            {
              label: 'actions',
              passthrough: true,
              template: module => html`
                <td>
                  <button
                    @click=${() => this.editModule(module)}
                    class="button is-text"
                    title="Edit"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:edit"
                    ></omni-icon>
                  </button>
                  <button
                    @click=${() => this.openModule(module)}
                    class="button is-text ml-4"
                    title="Open"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:launch"
                    ></omni-icon>
                  </button>
                  <button
                    @click=${() => this.removeModule(module)}
                    class="button is-text ml-4"
                    title="Remove"
                  >
                    <omni-icon
                      class="is-size-2"
                      icon-id="omni:interactive:remove"
                    ></omni-icon>
                  </button>
                </td>
              `,
            },
          ]}
          .data="${this.data ?? []}"
        >
          <slot name="header-start" slot="header-start"></slot>
          <slot name="header-end" slot="header-end"></slot>
          ${!this.data || this.data.length === 0
            ? html`
                <div class="has-text-centered p-5" slot="body">
                  <i>
                    ${this.data
                      ? 'There are currently no modules.'
                      : 'Loading...'}
                  </i>
                </div>
              `
            : ''}
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('step-modules', StepModules);
